<template>
  <div class="content">
    <div class="header d-flex align-items-center profile-header">
      <!-- Mask -->
      <span class="mask bg-gradient-info"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-12">
            <h1 class="display-3 text-white">Create User</h1>
          </div>
        </div>
      </div>
    </div>

    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta && $route.meta.name ? $route.meta.name : $route.name }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-8">
          <template>
            <card v-loading="loading">
              <div slot="header" class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0" id="user_details_heading">User Details</h3>
                </div>
                <div class="col-4 text-right">
                  <a href="javascript:" class="btn btn-sm btn-primary user-save" @click="createUser">Save</a>
                </div>
              </div>
              <user-info-form :user.sync="form" :isEditing="true"></user-info-form>
            </card>
          </template>
        </div>
      </div>
    </div>
    <!--Notification modal-->
    <modal
      :show="showWarning"
    >
      <template v-slot:header>
        <h6 class="modal-title">Please check below issues,  before creating user</h6>
      </template>

      <div class="py-3 text-center">
        <div 
          v-if="!hubpostAccess">
          <h4 class="heading mt-4">Hubspot Access</h4>
          <p>
            Please create a user in Hubspot with the email - {{form.ew_email}}.
          </p>
        </div>
        <div 
          v-if="!hubpostEmailAccess">
          <h4 class="heading mt-4">Hubspot Email Setup</h4>
          <p>
            Please create a user in Hubspot with the email - {{form.vc_email}} for email setup.
          </p>
        </div>
      </div>

      <template v-slot:footer>
        <base-button
          type="primary"
          class="ml-auto"
          @click="showWarning = false"
        >Close</base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import UserInfoForm from '@/views/Widgets/Users/UserInfoForm';
import Vue from 'vue';
export default {
  components: {
    UserInfoForm,
  },
  data() {
    return {
      form: {
        username: '',
        role: '',
        first_name: '',
        last_name: '',
        ew_email: '',
        password: '',
        user_id: '',
        is_venue_specialist: false,
        vc_email: '',
        phone_number: '',
        country_code: '',
        hs_owner_id: '',
        venue_specialist_id: '',
      },
      loading: true,
      showWarning: false,
    };
  },
  async created() {
    await this.$store.dispatch("users/getOwners");
    this.loading = false;
  },
  computed: {
    hubpostAccess() {
      let owner = this.$store.getters["users/getUserByEmail"](this.form.ew_email);
      return owner.email ? true : false;
    },
    hubpostEmailAccess() {
      let owner = this.$store.getters["users/getUserByEmail"](this.form.vc_email);
      return owner.email ? true : false;
    },
    mainOwnerId() {
      let owner = this.$store.getters["users/getUserByEmail"](this.form.ew_email);
      return owner.owner_id;
    },
    emailOwnerId() {
      let owner = this.$store.getters["users/getUserByEmail"](this.form.vc_email);
      return owner.owner_id;
    },
  },
  methods: {
    validateUser() {
      if (this.form.is_venue_specialist && !this.hubpostAccess) {
        return false;
      }
      if (this.form.is_venue_specialist && !this.hubpostEmailAccess) {
        return false;
      }
      return true;
    },
    async createUser() {
      if (!this.validateUser()) {
        this.showWarning = true;
        return;
      }
      this.loading = true;
      let userDetails = await this.$store.dispatch('users/createUser', this.form);
      this.loading = false;
      if (userDetails && userDetails.data && userDetails.data.errors) {
        let self = this;
        Object.entries(userDetails.data.errors).forEach(item => {
          self.$store.dispatch('alert/error', item.pop(), { root: true });
        });
      } else if (userDetails && userDetails.data.user) {
        this.$store.dispatch('alert/success', "User created successfully.", { root: true });
        this.$router.push({ name: 'UserDetails', params: {id: userDetails.data.user} });
      }
    },
  },
  watch: {
    form: {
      handler() {
        if (this.form.ew_email) {
          let data = this.form;
          data.hs_owner_id = this.mainOwnerId;
          this.form = data;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.btn {
  color: white;
}
.btn:hover {
  color: white;
}
</style>

