<template>
  <form  id="userinformation">
    <div>
      <h6 class="heading-small text-muted mb-4">
        User Information
      </h6>
      <div>
        <div class="row">
          <div class="col-lg-6">
            <base-input
              :disabled="userInputDisabled"
              type="text"
              label="Username"
              id="user_information_username"
              placeholder="Username"
              autocomplete="off"
              v-model="form.username"
            >
            </base-input>
          </div>
          <div class="col-lg-6">
            <base-input
              :disabled="userInputDisabled"
              type="password"
              label="Password"
              id="user_information_password"
              placeholder="Password"
              autocomplete="off"
              v-model="form.password"
            >
            </base-input>
          </div>
          <div class="col-lg-6">
            <base-input
              :disabled="userInputDisabled"
              label="Role"
              id="user_information_role"
              placeholder="Role"
            >
              <el-select v-model="form.role" placeholder="Role" :disabled="userInputDisabled">
                <el-option
                  label="Editor"
                  value="editor">
                </el-option>
                <el-option
                  v-if="isAdmin"
                  label="Administrator"
                  value="administrator">
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-lg-6">
            <base-input
              :disabled="userInputDisabled"
              label="User Access"
              id="user_information_access"
              placeholder="User Access"
            >
              <el-select v-model="form.vc_role" placeholder="User Access" :disabled="userInputDisabled">
                <el-option
                  label="Sales"
                  value="Sales">
                </el-option>
                <el-option
                  v-if="isAdmin"
                  label="Team Lead"
                  value="Team Lead">
                </el-option>
                <el-option
                  v-if="isAdmin"
                  label="Admin"
                  value="Admin">
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-lg-6">
            <base-input
              :disabled="userInputDisabled"
              type="email"
              label="EasyWeddings Email Address"
              id="user_information_ew_email"
              placeholder="mike@email.com"
              v-model="form.ew_email"
            >
            </base-input>
          </div>
          <div class="col-lg-6">
            <base-input
              :disabled="userInputDisabled"
              type="text"
              label="First Name"
              id="user_information_first_name"
              placeholder="First Name"
              v-model="form.first_name"
            >
            </base-input>
          </div>
          <div class="col-lg-6">
            <base-input
              :disabled="userInputDisabled"
              type="text"
              label="Last Name"
              id="user_information_last_name"
              placeholder="Last Name"
              v-model="form.last_name"
            >
            </base-input>
          </div>
          <div class="col-lg-12 mb-5">
            <hr/>
            <label class=" col-form-label form-control-label pr-3" style="vertical-align: super">Venue Specialist</label>
            <span id="toggle_is_venue_specialist">
              <base-switch v-model="form.is_venue_specialist"></base-switch>
            </span>
          </div>
          
        </div>
        <div class="row">
          <div class="col-lg-6">
            <base-input
              :disabled="userInputDisabled"
              type="email"
              label="VenueCrew Email Address"
              id="user_information_vc_email"
              placeholder="mike@email.com"
              v-model="form.vc_email"
              v-if="form.is_venue_specialist"
            >
            </base-input>
          </div>
          <div class="col-lg-6">
            <base-input
              :disabled="userInputDisabled"
              type="phone"
              label="Kixie Phone Number"
              id="user_information_phone"
              placeholder="0400 123 123"
              v-model="form.phone_number"
              v-if="form.is_venue_specialist"
            >
            </base-input>
          </div>
          <div class="col-lg-6">
            <base-input
              :disabled="userInputDisabled"
              type="phone"
              label="Message Media Phone Number"
              id="user_information_media_phone"
              placeholder="0400 123 123"
              v-model="form.message_media_phone_number"
              v-if="form.is_venue_specialist"
            >
            </base-input>
          </div>
          <div class="col-lg-6">
            <base-input
              :disabled="userInputDisabled"
              label="Country"
              id="user_information_country"
              placeholder="AU"
              v-if="form.is_venue_specialist"
            >
              <el-select v-model="form.country_code" placeholder="Country" :disabled="userInputDisabled">
                <el-option
                  label="AU"
                  value="AU">
                </el-option>
                <el-option
                  label="UK"
                  value="UK">
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-lg-6">
            <base-input
              :disabled="userInputDisabled"
              type="text"
              label="Hubspot owner id"
              id="user_information_owner_id"
              placeholder=""
              v-model="form.hs_owner_id"
              v-if="form.is_venue_specialist"
            >
            </base-input>
          </div>
          <div class="col-lg-6">
            <base-input
              :disabled="userInputDisabled"
              label="SMS Provider"
              id="user_information_sms_provider"
              placeholder="AU"
              v-if="form.is_venue_specialist"
            >
              <el-select v-model="form.sms_provider" placeholder="Provider" :disabled="userInputDisabled">
                <el-option
                  label="Kixie"
                  value="kixie">
                </el-option>
                <el-option
                  label="MessageMedia"
                  value="messagemedia">
                </el-option>
              </el-select>
            </base-input>
          </div>        
          <div class="col-lg-6">
            <base-input
              :disabled="userInputDisabled"
              label="Service"
              placeholder="Service"
              v-if="form.is_venue_specialist"
            >
              <el-select v-model="form.service_role" placeholder="Service" :disabled="userInputDisabled">
                <el-option
                  label="Content"
                  value="Content">
                </el-option>
                <el-option
                  label="Sales"
                  value="Sales">
                </el-option>
                <el-option
                  label="Admin"
                  value="Admin">
                </el-option>
              </el-select>
            </base-input>
          </div>   
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import {
  Table,
  TableColumn,
  Select,
  Option
} from "element-ui";

export default {
  props: {
    isEditing: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: false,
    },
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      loading: true,
      form: {
        username: '',
        role: '',
        vc_role: '',
        first_name: '',
        last_name: '',
        ew_email: '',
        password: '',
        user_id: '',
        is_venue_specialist: false,
        vc_email: '',
        phone_number: '',
        message_media_phone_number: '',
        sms_provider: '',
        country_code: '',
        hs_owner_id: '',
        venue_specialist_id: '',
        service_role:'',
      },
    };
  },
  computed: {
    userInputDisabled() {
      return this.isEditing ? false : true;
    },
    isAdmin() {
      return this.$store.getters["auth/isAdministrator"];
    },
  },
  watch: {
    user() {
      this.form = this.user;
    },
    form: {
      handler() {
        this.$emit('update:user' , this.form);
      },
      deep: true,
    },
  },
};
</script>